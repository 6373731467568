import { Gradient, Gradients } from "@styles/design-tokens/01.colors/gradients";
import { Scale } from "@theme-ui/css";

export const mapTokenGradientToCSSGradient = (gradient: Gradient): string => {
  const gradientType = `${gradient.type || "linear"}-gradient`;
  const gradientRotate = `${
    gradient.type === "radial" ? "circle" : `${gradient.rotate || 0}deg`
  }`;

  return [
    gradientType,
    `(${gradientRotate},`,
    ...gradient.colors.map(
      (color, i) =>
        `${color.color} ${
          color.hasOwnProperty("stop")
            ? color.stop
            : i === 0
            ? 0
            : ((i + 1) / gradient.colors.length) * 100
        }%${i !== gradient.colors.length - 1 ? "," : ")"}`
    ),
  ].join("");
};

export const mapTokenGradientsToCSSGradients = (
  gradients: Gradients
): Scale<string> => {
  const cssGradients = {};
  Object.entries(gradients).forEach(
    ([key, value]) => (cssGradients[key] = mapTokenGradientToCSSGradient(value))
  );
  return cssGradients;
};
