export interface GradientColor {
  color: string;
  stop?: number;
}

export interface Gradient {
  colors: GradientColor[];
  rotate?: number;
  type?: "linear" | "radial";
}

export interface Gradients {
  [name: string]: Gradient;
}

const gradients: Gradients = {};

export default gradients;
