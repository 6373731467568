import "./src/polyfills";

import React from "react";
import { Providers } from "./src/Providers";

import "./src/styles/fonts.css";
import "./src/styles/reset.css";

export const wrapRootElement = ({ element }) => (
  <Providers>{element}</Providers>
);
