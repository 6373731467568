import React, { PropsWithChildren } from "react"
// import { ThemeProvider, Theme } from "theme-ui"
import { ApiProvider } from "./carlsberg-headless/api/ApiProvider"
// import { theme } from "@styles/theme"
// import { ReactQueryDevtools } from "react-query-devtools"
import { QueryClient, QueryClientProvider } from "react-query"

const queryClient = new QueryClient()

const Providers: React.FC<PropsWithChildren> = ({ children }) => (
  <>
    <QueryClientProvider client={queryClient}>
      <ApiProvider>
        {/* <ThemeProvider theme={theme as Theme}>{children}</ThemeProvider> */}
        {children}
        {/* <ReactQueryDevtools initialIsOpen /> */}
      </ApiProvider>
    </QueryClientProvider>
  </>
)

export { Providers }
