export default {
  full: "100%",
  screenHeight: "100vh",
  screenWidth: "100vw",
  width: {
    0: "670px",
    1: "700px",
    2: "840px",
    3: "1040px",
    4: "1440px",
  },
  square: {
    icon: "32px",
    avatar: "64px",
  },
};
