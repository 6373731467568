import { Theme as ThemeUITheme } from "theme-ui";
import { Scale, ThemeUIStyleObject } from "@theme-ui/css";
import { mapPxToEm } from "@styles/utils/mapPxToEm";
import colors from "@styles/design-tokens/01.colors/colors";
import typography from "@styles/design-tokens/02.typography";
import spacing from "@styles/design-tokens/03.spacing/spacing";
import sizes from "@styles/design-tokens/04.sizes/sizes";
import radii from "@styles/design-tokens/05.radii/radii";
import borderWidths from "@styles/design-tokens/06.border-widths/border-widths";
import opacities from "@styles/design-tokens/07.opacities/opacities";
import shadows from "@styles/design-tokens/08.shadows/shadows";
import breakpoints from "@styles/design-tokens/10.breakpoints/breakpoints";
import zIndices from "@styles/design-tokens/11.z-indices/z-indices";
import aspectRatios from "@styles/design-tokens/12.aspect-ratios/aspect-ratios";
import { mapPxToRem } from "@styles/utils/mapPxToRem";
import {
  mapTokenGradientsToCSSGradients,
  // mapTokenGradientToCSSGradient,
} from "@styles/utils/mapTokenGradientToCSSGradient";
import gradients from "@styles/design-tokens/01.colors/gradients";

export interface Theme extends ThemeUITheme {
  // extra token types
  aspectRatios: Scale<string>;
  gradients: Scale<string>;
  // extra variants
  icons?: Record<string, ThemeUIStyleObject>;
  accordion?: Record<string, ThemeUIStyleObject>;
  menu?: Record<string, ThemeUIStyleObject>;
  cardSplash?: Record<string, ThemeUIStyleObject>;
  mediaSlider?: Record<string, ThemeUIStyleObject>;
  textModule?: Record<string, ThemeUIStyleObject>;
  layoutContainer?: Record<string, ThemeUIStyleObject>;
}

// map design tokens to theme-ui theme properties
const theme: Theme = {
  // Config
  useCustomProperties: false,
  // useBodyStyles: false,
  useBorderBox: true,
  // System
  breakpoints: mapPxToEm(breakpoints) as string[],
  // Colors
  colors,
  // Typography
  ...typography,
  // Spacing
  space: mapPxToRem(spacing) as Scale<string>,
  // Sizes
  sizes,
  // Borders
  radii,
  borderWidths,
  //  Misc.
  opacities,
  shadows,
  aspectRatios,
  zIndices,
  gradients: mapTokenGradientsToCSSGradients(gradients),
  // Variants
  grids: {},
  buttons: {
    burger: {
      position: "relative",
      bg: "rgba(0, 0, 0, 0.07)",
      width: "70px",
      height: "70px",
      transition: "background-color .3s ease-in-out",
      "&:before": {
        content: "''",
        width: "50px",
        height: "50px",
        bg: "white",
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate3d(-50%, -50%, 0)",
        borderRadius: "full",
      },
      ":hover, :focus": {
        bg: "rgba(0,0,0,.1)",
      },
    },
    white: {
      bg: "transparent",
      background: "transparent",
      borderColor: "white",
      color: "white",
      "> span": {
        color: "darkGreen",
      },
      "&:hover, &:focus": {
        "> span": {
          color: "white",
        },
        "> svg": {
          fill: "black",
        },
      },
    },
    copper: {
      bg: "transparent",
      color: "copper",
      borderColor: "copper",
      "> span": {
        color: "white",
      },
      "&:hover, &:focus": {
        "> span": {
          color: "copper",
        },
      },
    },
    green: {
      bg: "transparent",
      borderColor: "darkGreen",
      color: "darkGreen",
      "> span": {
        color: "white",
      },
      "&:hover, &:focus": {
        "> span": {
          color: "darkGreen",
        },
      },
    },
    greenSelected: {
      bg: "darkGreen",
      borderColor: "darkGreen",
      color: "lightGray",
      "> span": {
        color: "white",
      },
      "> svg": {
        fill: "white",
      },
      "&:hover, &:focus": {
        backgroundPosition: "0 0!important",
        bg: "darkGreen",
        "> span": {
          color: "white!important",
        },
        "> svg": { fill: "white!important" },
      },
    },
    red: {
      bg: "transparent",
      borderColor: "darkRed",
      color: "darkRed",
      "> span": {
        color: "white",
      },
      "&:hover, &:focus": {
        "> span": {
          color: "darkRed",
        },
      },
    },
    whiteOutline: {
      bg: "transparent",
      color: "white",
      borderColor: "white",
      "> span": {
        color: "white",
      },
      "&:hover, &:focus": {
        "> span": {
          color: "black",
        },
        "> svg": {
          fill: "black",
        },
        "> svg path": {
          stroke: "black",
        },
      },
    },
    copperOutline: {
      bg: "transparent",
      color: "copper",
      borderColor: "copper",
      "> span": {
        color: "copper",
      },
      "&:hover, &:focus": {
        "> span": {
          color: "white",
        },
      },
    },
    greenOutline: {
      bg: "transparent",
      color: "darkGreen",
      borderColor: "darkGreen",
      "> span": {
        color: "darkGreen",
      },
      svg: {
        width: "20px",
        path: {
          fill: "darkGreen",
          stroke: "darkGreen",
        },
      },
      "&:hover, &:focus": {
        "> span": {
          color: "white",
        },
        svg: {
          path: {
            fill: "white",
            stroke: "white",
          },
        },
      },
    },
    redOutline: {
      bg: "transparent",
      color: "darkRed",
      borderColor: "darkRed",
      "> span": {
        color: "darkRed",
      },
      "&:hover, &:focus": {
        "> span": {
          color: "white",
        },
      },
    },
  },
  text: {
    // <Text/> default
    default: {
      mb: 4,
    },
    // <Text/> variants
    paragraphLarge: {
      fontSize: "paragraphLarge",
    },
    paragraphSmall: {
      fontSize: "paragraphSmall",
    },
    sectionText: {
      fontSize: ["xsText", "paragraph", "paragraph"],
      color: "white",
      letterSpacing: "3px",
      fontFamily: "heading",
      lineHeight: "tight",
    },
    cardSectionText: {
      fontSize: "paragraphSmall",
      color: "copper",
      fontWeight: "normal",
      fontFamily: "regular",
      letterSpacing: "2px",
      textTransform: "uppercase",
    },
    // <Heading/> default
    heading: {
      // fontFamily: "heading",
      // fontWeight: "regular",
      // lineHeight: "none",
      mb: 4,
    },
    // <Heading/> variants
    h1: {
      fontSize: "h1",
    },
    h2: {
      fontSize: "h2",
    },
    h3: {
      fontSize: "h3",
    },
    h4: {
      fontSize: "h4",
    },
    h5: {
      fontSize: "h5",
    },
    h6: {
      fontSize: "h6",
    },
    display1: {
      fontSize: "display1",
    },
  },
  links: {},
  images: {},
  layout: {
    container: {
      maxWidth: ["full", "maxWidth.lg", "maxWidth.5xl"],
    },
  },
  layoutContainer: {
    default: {
      bg: "lightGray",
    },
  },
  cards: {
    primary: {
      borderRadius: 0,
      bg: "white",
    },
    shadow: {
      boxShadow: "0px 8px 18px rgba(26, 9, 2, 0.2);",
      bg: "white",
    },
  },
  // Custom Variants
  icons: {
    footer: {
      _facebook: {
        width: "19px",
        height: "16px",
        fill: "white",
      },
      _instagram: {
        width: "19px",
        height: "19px",
        fill: "white",
      },
      _youtube: {
        width: "19px",
        height: "19px",
        fill: "white",
      },
      _twitter: {
        width: "19px",
        height: "19px",
        fill: "white",
      },
      _linkedin: {
        width: "19px",
        height: "19px",
        fill: "white",
      },
    },
  },
  accordion: {
    secondary: {
      color: "secondary",
    },
  },
  menu: {
    secondary: {
      color: "secondary",
    },
    transparent: {
      color: "yellow",
      bg: "transparent",
      border: "none",
    },
  },
  cardSplash: {
    custom: {
      backgroundColor: "copper",
      color: "darkGray",
    },
    cancelled: {
      backgroundColor: "red",
      color: "copper",
    },
  },
  mediaSlider: {
    container: {
      maxWidth: ["full", "width.2"],
    },
  },
  textModule: {
    alignment: {
      default: {
        textAlign: "left",
      },
      center: {
        textAlign: "center",
      },
      left: {
        textAlign: "left",
      },
    },
  },
};

export { theme };
