export default {
  display1: "140px",
  h1: "72px",
  h2: "38px",
  h3: "28px",
  h4: "24px",
  h5: "20px",
  h6: "18px",
  paragraphLarge: "24px",
  headingFlavour: "56px",
  paragraph: "20px",
  paragraphMedium: "16px",
  paragraphSmall: "14px",
  smText: "12px",
  xsText: "11px",
  tinyText: "10px",
};
