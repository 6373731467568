import { mapPxToRem } from "@styles/utils/mapPxToRem";

import fontFamilies from "@styles/design-tokens/02.typography/01.font-families/font-families";
import fontSizes from "@styles/design-tokens/02.typography/02.font-sizes/font-sizes";
import fontWeights from "@styles/design-tokens/02.typography/03.font-weights/font-weights";
import lineHeights from "@styles/design-tokens/02.typography/04.line-heights/line-heights";
import letterSpacings from "@styles/design-tokens/02.typography/05.letter-spacings/letter-spacings";

export default {
  fontWeights,
  lineHeights,
  letterSpacings,
  // Build up font-stacks from font-family tokens
  fonts: {
    regular: `${fontFamilies["NyCarlsbergDisplay-Regular"]}, ${fontFamilies["System Fonts"]}`,
    body: `${fontFamilies["NyCarlsbergDisplay-Light"]}, ${fontFamilies["System Fonts"]}`,
    heading: `${fontFamilies["NyCarlsbergDisplay-Bold"]}, ${fontFamilies["System Fonts"]}`,
  },
  // Map px values to rem values - rem-base is 16 default
  // { body: "16px" } > { body: "1rem" }
  fontSizes: mapPxToRem(fontSizes) as Record<string, string>,
};
