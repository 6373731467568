// export default {
//   gray: {
//     0: "#F4F4F3", // Lighter Gray
//     1: "#D9D9D6", // Light Gray
//     2: "#333333", // Gray
//     3: "#2D2F33", // Dark Gray
//     4: "#060606", // Primary Dark
//     5: "#C4C4C4",
//   },
//   red: {
//     0: "#561415", // Dark Red/Primary Background
//     1: "#EB5757",
//   },
//   yellow: {
//     0: "#DEC673", // Copper
//   },
//   green: {
//     0: "#A0D035", // Light Green
//     1: "#006939", // Dark Green/Primary Green
//   },
//   white: "#FFFFFF",
//   black: "#000000",
// };

export default {
  gray: {
    0: "#F8F8F8", // Lightest Gray
    1: "#EBEBE9", // Light Gray
    2: "#D9D9D6", // Gray / Cool Gray
    3: "#2D2F33", // Dark Gray
    4: "#060606", // Primary Dark
    5: "#63666A", // Medium Gray
  },
  red: {
    0: "#581515", // Dark Red
  },
  yellow: {
    0: "#C3A354", // Copper
  },
  green: {
    0: "#006939", // Light Green / Ny Dawn Green
    1: "#284A41", // Dark Green / Primary Green / Dusty Green
  },
  white: "#FFFFFF", // White
  black: "#000000", // Black
};
