import {
  createClient,
  CbSDKClientOptions,
} from "@akqa_denmark/cb-sdk/build-es5";
import { CbSDKApi } from "@akqa_denmark/cb-sdk/build-es5/create-api";

const isCarlsbergHeadlessPublished =
  process.env.GATSBY_CB_HEADLESS_ENV === "published";

const options: CbSDKClientOptions = {
  apiKey: process.env.GATSBY_CARLSBERG_HEADLESS_API_KEY as string,
  apiUrl: process.env.GATSBY_CARLSBERG_HEADLESS_API_URL as string,
  siteId: process.env.GATSBY_CARLSBERG_HEADLESS_SITE_ID as string,
  preview: !isCarlsbergHeadlessPublished,
};

const cbSdkClient: CbSDKApi = createClient(options);

export default cbSdkClient;
