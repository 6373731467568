export default {
  // increment by 5
  0: "0px",
  1: "5px",
  2: "10px",
  3: "15px",
  4: "20px",
  5: "25px",
  6: "30px",
  7: "35px",
  8: "40px",
  9: "45px",
  10: "50px",
  // increment by 10
  11: "60px",
  12: "70px",
  13: "80px",
  14: "90px",
  15: "100px",
  16: "110px",
  17: "120px",
  18: "130px",
  19: "140px",
  20: "150px",
};
