import hocPalettes from "@styles/design-tokens/01.colors/palettes";

export default {
  /* HOC Colors */
  background: hocPalettes.white,
  white: hocPalettes.white,
  black: hocPalettes.black,
  text: hocPalettes.gray["3"],
  gray: hocPalettes.gray["2"],
  mediumGray: hocPalettes.gray["5"],
  lighterGray: hocPalettes.gray["0"],
  lightGray: hocPalettes.gray["1"],
  darkGray: hocPalettes.gray["3"],
  primaryGray: hocPalettes.gray["4"],
  downloadGray: hocPalettes.gray["2"],
  navHoverGray: hocPalettes.gray["1"],
  darkRed: hocPalettes.red["0"],
  // red: hocPalettes.red["1"],
  copper: hocPalettes.yellow["0"],
  lightGreen: hocPalettes.green["0"],
  darkGreen: hocPalettes.green["1"],
};
