import React, { PropsWithChildren, createContext } from "react"
import cbSdkClient from "./cb-sdk-provider"
import { CbSDKApi } from "@akqa_denmark/cb-sdk/build-es5/create-api"

const ApiContext = createContext<CbSDKApi>({} as CbSDKApi)

const apiService = cbSdkClient

const ApiContextProvider = ApiContext.Provider

/**
 * Provides a singleton ApiService to all children
 */
const ApiProvider: React.FC<PropsWithChildren> = ({ children }) => (
  <ApiContextProvider value={apiService}>{children}</ApiContextProvider>
)

export { ApiContext, ApiProvider }
